(function($){
	$( document ).ready(function() {
		console.log( "ready!" );

		$('.js-more').on('click', function(e){
			e.preventDefault();
			$(this).addClass('open');
		});


		$('#hero-gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			infinite: true,
			speed: 200,
			fade: true,
			cssEase: 'linear',
			dots: false,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			touchMove: false
		});

		$('#atelier-gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			infinite: true,
			speed: 200,
			fade: true,
			cssEase: 'linear',
			dots: false,
			arrows: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			// appendArrows: '.gallery-arrows'
			prevArrow: '.gallery-arrows .prev',
			nextArrow: '.gallery-arrows .next'
		});

		$('#technik-01-gallery,#technik-02-gallery,#technik-03-gallery,#technik-04-gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			infinite: true,
			speed: 200,
			fade: true,
			cssEase: 'linear',
			dots: false,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			touchMove: false
		});
		
		$('.js-cookie-toggle').on('click', function(e){
			e.preventDefault();
			$('.cookie').removeClass('visited', 'opt-out', 'opt-in');
		});

	});
})(jQuery); 